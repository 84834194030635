<template>
  <div>
    <v-card>
      <v-card-text class="pb-12">
        <excel-clipboard-to-object
          @result="getResult"
          :height="200"
        ></excel-clipboard-to-object>

        <v-row class="mt-5">
          <v-col
            cols="1"
            class=""
          >
            <render-input
              :input="inputConfig.rowsNumber"
              v-model="columnNumber"
            ></render-input>
          </v-col>
          <v-col
            cols="10"
            class="pl-5"
          >
            <render-input
              :input="inputConfig.template"
              v-model="template"
            ></render-input>
          </v-col>
          <v-col
            cols="1"
            class=""
          >
            <render-input
              :input="inputConfig.quote"
              v-model="quote"
            ></render-input>
          </v-col>

        </v-row>

        <div class="my-5">
          <v-btn
            @click="generateResult()"
            color="success"
          >Generate</v-btn>
        </div>

        <table class="tg">
          <tr>
            <td
              v-for="(column,index) in column"
              style="height:20px"
              :key="index"
            >
              <render-input
                :input="inputConfig.oldValue"
                v-model="column.oldValue"
              ></render-input>

              <render-input
                class="mt-n5"
                :input="inputConfig.dataType"
                v-model="column.dataType"
              ></render-input>
            </td>
          </tr>
          <tr
            v-for="(row,i) in rows"
            :key="i"
          >
            <td
              class="tg-0lax"
              v-for="(col,j) in row"
              :key="j"
            >{{col}}</td>
          </tr>
        </table>

        <div class="mt-2">
          <custom-copy-value
            :item="result"
            :alwaysDisplay="true"
            :inline="true"
          ></custom-copy-value>

          <textarea
            cols="30"
            rows="10"
            style="border : 1px solid black"
            :value="JSON.stringify(result, null, 2)"
          >
        </textarea>

        </div>

      </v-card-text>
    </v-card>

  </div>
</template>


<script>
import { InputType } from "../js/constants";

export default {
  data() {
    return {
      rows: [],
      columnNumber: 0,
      template: null,
      quote: true,
      column: [],
      result: "",
      dataTypes: [
        {
          id: "text",
          text: "text",
        },
        {
          id: "number",
          text: "number",
        },
        {
          id: "guid",
          text: "guid",
        },
        {
          id: "date",
          text: "date",
        },
      ],
    };
  },
  watch: {
    columnNumber: {
      handler(newVal) {
        this.generateColumn();
      },
    },
  },
  methods: {
    generateColumn() {
      this.column = [];
      for (let i = 0; i < Number(this.columnNumber); i++) {
        this.column.push({
          order: i,
          oldValue: null,
          dataType: null,
        });
      }
    },
    generateTemplate() {
      this.result = "";
      let strQuote = this.quote ? '"' : "";
      this.rows.forEach((row) => {
        let temp = this.template;

        row.forEach((col, index) => {
          let columnConfig = this.column[index];
          let oldValue = columnConfig.oldValue;
          let newVal;
          if (columnConfig.dataType?.id == "number") {
            newVal = col;
          } else if (columnConfig.dataType?.id == "text") {
            newVal = `${strQuote}${col}${strQuote}`;
          } else if (columnConfig.dataType?.id == "guid") {
            newVal = `new Guid(${strQuote}${col}${strQuote})`;
          } else {
            newVal = `${strQuote}${col}${strQuote}`;
          }

          temp = temp.replace(oldValue, newVal);
        });
        this.result += temp;
        var newline = String.fromCharCode(13, 10);

        // this.result += "\n";
        this.result += newline;
      });
    },
    generateObject() {
      this.result = [];

      this.rows.forEach((row) => {
        let obj = {};

        row.forEach((col, index) => {
          let columnConfig = this.column[index];
          let oldValue = columnConfig.oldValue;

          if (columnConfig.dataType?.id == "number") {
            try {
              obj[oldValue] = Number(col);
            } catch (e) {
              obj[oldValue] = col;
            }
          } else {
            obj[oldValue] = col;
          }
        });
        this.result.push(obj);
      });
    },
    generateResult() {
      if (this.template) {
        this.generateTemplate();
      } else {
        this.generateObject();
      }
    },
    getResult(res) {
      this.rows = res;
    },
  },
  computed: {
    inputConfig() {
      return {
        template: {
          text: "Template string",
          type: InputType.text,
        },
        quote: {
          text: "ใช้ Quote",
          type: InputType.checkBox,
        },
        rowsNumber: {
          text: "จำนวนคอลัมน์",
          type: InputType.number,
        },
        type: {
          text: "จำนวนคอลัมน์",
          type: InputType.dropdown,
          data: {},
        },
        oldValue: {
          text: "oldValue",
          type: InputType.text,
        },
        dataType: {
          text: "dataType",
          type: InputType.dropdown,
          data: {
            promise: Promise.resolve({
              items: this.dataTypes,
            }),
          },
        },
      };
    },
  },
};
</script>


<style type="text/css">
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}
</style>